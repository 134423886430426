<template>
  <b-container fluid>
    <ValidationObserver ref="customerFormObserver" v-slot="{ invalid }">
      <form>
        <!--User Information -->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-4" :label="$t('NameSurname')" label-for="fname">
                      <ValidationProvider name="First and last name" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="model.name" type="text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ $t("ValidationMessage.CannotBeEmpty") }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('IdentityNumber')" label-for="idnumber">
                      <ValidationProvider name="Identity number" :rules="identityNumberRules" v-slot="{ errors }">
                        <b-form-input v-model="model.nationalNumber" type="text" :class="(errors.length > 0 && setRequired('citizenshipID_Required') ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ $t("ValidationMessage.CannotBeEmpty") }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="gender"
                                  :label="$t('Gender')">
                      <v-select transition="" v-model="model.genderID"
                                :reduce="s => s.id" label="name"
                                :options="genderList"
                                name="GenderID">
                      <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="nationality"
                                  :label="$t('Nationality')">
                      <v-select transition="" v-model="model.nationalityCountryID"
                                :reduce="s => s.id" label="name"
                                :options="countryList"
                                name="Nationality">
                      <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('Phone')" label-for="mobno">
                      <ValidationProvider name="Mobile Number" rules="required" v-slot="{ errors }">
                        <phone-number v-model.lazy="model.phoneObject" :objectId="model.id" :allow-dublicate-number="setRequired('blockOnDuplicatePhoneNumber')" />
                        <div class="invalid-feedback">
                          <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  :label="$t('Email')"
                                  label-for="email">
                      <ValidationProvider vid="email" name="E-mail" rules="email" v-slot="{ errors }">
                        <input type="email"
                               :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
                               id="emailInput"
                               maxlength="250"
                               v-model="model.eMail"
                               :required="setRequired('emailRequired')">
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="referenceSource"
                                  :label="$t('ReferenceSource')">
                      <ValidationProvider name="ReferenceSource" :rules="referenceSourceRules" v-slot="{ errors }">
                        <v-select transition="" v-model="model.referenceSourceID"
                                  :reduce="s => s.id" label="name"
                                  :options="referenceSourceList"
                                  name="ReferenceSource"
                                  :class="(errors.length > 0 && setRequired('referenceSourceRequired') ? ' is-invalid' : '')">
                        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                        <div class="invalid-feedback">
                          <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="country"
                                  :label="$t('Country')">
                      <ValidationProvider name="Country" :rules="countryRules" v-slot="{ errors }">
                        <v-select transition="" v-model="model.countryID"
                                  :reduce="s => s.id" label="name"
                                  :searchable="true"
                                  :options="countryList"
                                  name="Country"
                                  :class="(errors.length > 0 && setRequired('countryRequired') ? ' is-invalid' : '')">
                        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                        <div class="invalid-feedback">
                          <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="city"
                                  :label="$t('City')">
                      <ValidationProvider name="City" :rules="cityRules" v-slot="{ errors }">
                        <v-select transition="" v-model="model.cityID"
                                  :reduce="s => s.id" label="name"
                                  :searchable="true"
                                  :options="cityList"
                                  name="City"
                                  :class="(errors.length > 0 && setRequired('cityRequired') ? ' is-invalid' : '')">
                        <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                        <div class="invalid-feedback">
                          <span>{{ $t('ValidationMessage.CannotBeEmpty') }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="town"
                                  :label="$t('Town')">
                      <v-select transition="" v-model="model.townID"
                                :reduce="s => s.id" label="name"
                                :searchable="true"
                                :options="townList"
                                name="Town">
                      <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                    </b-form-group>
                    <b-form-group class="col-md-4" :label="$t('DateOfBirth')" label-for="exampleInputdate">
                      <date-picker v-model="model.dateOfBirth"
                                   :tag-name="'DateOfBirth'"
                                   :is-required="setRequired('dateOfBirthRequired') ? true : false"
                                   :max-date-today="true" />
                    </b-form-group>
                    <b-form-group class="col-md-4"
                                  label-for="language"
                                  :label="$t('Language')">
                      <v-select transition="" v-model="model.languageID"
                                :reduce="s => s.id" label="name"
                                :options="languageList"
                                name="Language">
                      <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!--Save or Cancel Buttons -->
        <b-row>
          <b-col lg="12" class="text-right">
            <div class="modal-footer">
              <b-overlay :show="isSubmitting"
                         spinner-small
                         spinner-variant="primary"
                         class="d-inline-block">
                <b-button :disabled="isSubmitting"
                          variant="primary"
                          @click="submitNewCustomer()">
                  {{ $t('Save') }}
                </b-button>
              </b-overlay>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import DatePicker from '../shared/DatePicker';
  import PhoneNumber from '../shared/PhoneNumber';

  import locationService from '../../services/location';
  import customerService from '../../services/customer';
  import commonService from '../../services/common';

  export default {
    name: 'NewCustomer',
    props: {
      customerId: String
    },
    components: {
      DatePicker,
      PhoneNumber,
    },
    data() {
      return {
        model: {
          name: null,
          phoneObject: { phoneSalt: '', internationalFormat: '', phoneCountryCode: '', phoneRegionCode: '', phoneNumberIsExists: null },
          nationalNumber: null,
          email: null,
          genderId: null,
          dateOfBirth: null,
          countryId: null,
          cityId: null,
          townId: null,
          representId: null,
          referenceSourceId: null,
          customerSegmentId: null,
          nationalityCountryId: null,
          enableSms: 1,
          enableEmail: 1,
          sourceContactMethodId: '',
          preferredContactMethodId: '',
          languageId: ''
        },
        validationVSelectErrors: {
          ReferenceSourceID: '',
          CountryID: '',
          CityID: '',
          RepresentID: '',
        },
        genderList: [],
        countryList: [],
        cityList: [],
        townList: [],
        languageList: [],
        referenceSourceList: [],
        customerTypeList: [],
        customerSettings: [],
        isValid: false,
        isSubmitting: false
      }
    },
    methods: {
      getCountryList: function () {
        locationService.getCountryList(this.$store.getters['Auth/currentLanguage']).then(
          (result) => {
            this.countryList = result;
          }
        );
      },
      getCities: function (countryId) {
        if (countryId != null) {
          locationService.getCityList(countryId).then(
            (result) => (this.cityList = result)
          );
        }
      },
      getTowns: function (cityId) {
        if (cityId != null) {
          locationService.getTownList(cityId).then(
            (result) => (this.townList = result)
          );
        }
      },
      getGenders: function () {
        customerService.getGenders().then((response) => {
          this.genderList = response;
        })
      },
      getLanguagesList() {
        commonService.getLanguagesList().then((response) => {
          this.languageList = response;
        }).finally(() => {
          let language = this.selectListLanguages.find(s => s.culture == this.$store.getters['Auth/tenantInfo'].culture);
          if (language != null)
            this.model.languageId = language.id;
          else
            this.model.languageId = this.selectListLanguages.find(s => s.culture == 'en-US').id;
        });
      },
      getReferenceSources() {
        commonService.getReferenceSourceList().then(response => {
          this.referenceSourceList = response;
        });
      },
      getCustomerSettings() {
        customerService.getCustomerSetting()
          .then(response => {
            this.customerSettings = response;

            if (this.customerSettings.genderSelectedValue) {
              this.model.genderID = this.customerSettings.genderSelectedValue;
            }
          }).finally(() => {
            this.validateForVSelect()
          });
      },
      setRequired(field) {
        if (this.customerSettings && field) {
          return this.customerSettings[field];
        }
      },
      validateForVSelect() {
        this.isValid = true;

        if (this.setRequired('countryRequired') && !this.model.countryID) {
          this.validationVSelectErrors.CountryID = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.CountryID = ''; }

        if (this.setRequired('cityRequired') && !this.model.cityID) {
          this.validationVSelectErrors.CityID = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.CityID = ''; }

        if (this.setRequired('referenceSourceRequired') && !this.model.referenceSourceID) {
          this.validationVSelectErrors.ReferenceSourceID = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.ReferenceSourceID = ''; }

        if (this.setRequired('dateOfBirthRequired') && !this.model.dateOfBirth) {
          this.validationVSelectErrors.DateOfBirth = this.$t('ValidationMessage.CannotBeEmpty');
          this.isValid = false;
        }
        else { this.validationVSelectErrors.DateOfBirth = ''; }

        return this.isValid;
      },
      async submitNewCustomer() {
        let isValid = await this.$refs.customerFormObserver.validate();

        if (!this.model.phoneObject.isPhoneValid) {
          isValid = false;
        }

        if (this.customerSettings.blockOnDuplicatePhoneNumber && this.model.phoneObject.phoneNumberIsExists) {
          isValid = false;
          this.$toastr.warning(this.$t("DuplicateNumberMessage"));
        }

        if (isValid) {
          this.isSubmitting = true;

          this.model.enableSms = this.model.enableSms == 2 ? 0 : 1;
          this.model.enableEmail = this.model.enableEmail == 2 ? 0 : 1;

          customerService.postCustomerFormFast(this.model).then(response => {
            if (response.success) {
              this.$bus.$emit('CustomerSelectionComponent_onSelected', response.id);
              this.$toastr.success(this.$t("Success"));
            }
            else {
              if (response.message && response.message.length > 0) {
                this.$toastr.error(this.$t(response.message));
              }
              else {
                this.$toastr.error(this.$t("Error"));
              }
            }
            this.isSubmitting = false;
          })
        } else {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
        }
      }
    },
    watch: {
      'model.name': function () {
        this.validateForVSelect();
      },
      selectedCountry: function (val) {
        this.validateForVSelect();
        this.getCities(val);
      },
      selectedCity: function (val) {
        this.validateForVSelect();
        this.getTowns(val);
      },
      'model.referenceSourceID': function () {
        this.validateForVSelect();
      },
      'model.dateOfBirth': function () {
        this.validateForVSelect();
      },
      'model.email': function (val) {
        if (val) {
          this.validateEmail(val);
        }
      },
      'model.phoneObject.phoneRegionCode': function (val) {
        if (val) {
          const country = this.countryList.find(z => z.region?.toLowerCase() == val?.toLowerCase());
          if (country) {
            this.model.countryID = country.id;
            this.model.nationalityCountryID = country.id;
          }

          if (val?.toLowerCase() != this.$store.getters['Auth/tenantInfo'].countryCode) {
            this.model.enableSms = 2;
          }
          else {
            this.model.enableSms = 1;
          }
        }
      },
      mainCountry: {
        immediate: true,
        handler(val) {
          this.model.countryId = val;
        }
      },
      immediate: true
    },
    computed: {
      fullName: function () {
        return this.user.fname + ' ' + this.user.lname
      },
      mainCountry: function () {
        return this.$store.getters['Auth/tenantInfo'].mainCountryId;
      },
      selectedCountry: function () {
        return this.model.countryID;
      },
      selectedCity: function () {
        return this.model.cityID;
      },
      identityNumberRules() {
        if (this.setRequired('citizenshipID_Required')) {
          return 'required';
        }
        return '';
      },
      emailValid() {
        return this.$refs.email.validate()
      },
      countryRules() {
        if (this.setRequired('countryRequired')) {
          return 'required';
        }
        return '';
      },
      cityRules() {
        if (this.setRequired('cityRequired')) {
          return 'required';
        }
        return '';
      },
      referenceSourceRules() {
        if (this.setRequired('referenceSourceRequired')) {
          return 'required';
        }
        return '';
      },
    },
    mounted() {
      this.getCustomerSettings();
      this.getCountryList();
      this.getGenders();
      this.getLanguagesList();
      this.getReferenceSources();
    }
  }
</script>
