import axios from './index'

export default {
  getCountryList(language) {
    return axios.get('/data/location/country?culture=' + language)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },

  getCityList(countryId) {
    return axios.get('/data/location/city?countryId=' + countryId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getForeignPatientTypeList() {
    return axios.get('/data/sagliknet/yabanciHastaTuru')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },

  getTownList(cityId) {
    return axios.get('/data/location/town?cityId=' + cityId)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },

  getTimeZone() {
    return axios.get('/data/location/timeZone')
      .then((response) => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },

}
